<!--
 * @Author: 主页面
 * @Date: 2021-05-13 10:03:58
 * @LastEditTime: 2021-07-04 23:15:12
 * @LastEditors: 学习
 * @Description: In User Settings Edit
 * @FilePath: \参考\my-app\src\App.vue
-->
<template>
	<div  class="cy-button cy">
		<button class="btn-flow-light-two">
			流光光按钮2
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</button>
	</div>
</template>

<script>
export default {
	name: '流光按钮2',
}
</script>

<style  scoped>
	/* 引入对应样式 */
	@import url('./but-style/btn-flow-light-two.css');
</style>
