<!--
 * @Author: 主页面
 * @Date: 2021-05-13 10:03:58
 * @LastEditTime: 2021-05-13 10:10:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \参考\my-app\src\App.vue
-->
<template>
	<div  class="cy-button cy">
		<button href="#" class="btn-fluorescence-one">fluorescence-button</button>
	</div>
</template>

<script>
export default {
	name: '荧光背景渐变按钮',
}
</script>

<style  scoped>
	/* 引入对应样式 */
	@import url('./but-style/btn-fluorescence-one.css');
</style>
